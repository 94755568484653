<template>
  <div class="mainPage">
    <div class="mainPageContent">
      <div class="contentPanel">
        <div class="headerLogo">
          <img
            src="../assets/logo_b.png"
            alt="Osmose"
            @click="openDashboard()"
          />
        </div>
        <div class="textCenter info1">
          Please enter the following information:
        </div>
        <div class="formList" >
          <div class="formGroup">
            <label>Pole ID <span>*</span></label>
            <input
              title=""
              type="text"
              class="formControl"
              maxlength="32"
              required
              v-model="poleData.poleId"
            />
            <div v-if="formSubmitted && !poleData.poleId" class="error">
              Pole ID cannot be empty
            </div>
          </div>

          <div 
            class="formGroup">
            <label>Original {{measurementType}} (inches)<span>*</span></label>
            
            <div style="display:flex">
              <input
                title=""
                type="number"
                class="formControl spinNone"
                min="0"
                required
                v-model="origValue"
                @keypress="decimalPoint($event, origValue)"
                @paste="decimalPoint($event, origValue)"
                @keyup="origChanged($event, origValue)"/>
                <label v-if="!isTape" style="min-width:150px; margin-top:10px;margin-left:10px;" >
                  Circ. = {{poleData.origCircumference}}
                </label>
            </div>
            <div
              v-if="formSubmitted && !poleData.origCircumference"
              class="error">
              Original {{measurementType}} cannot be empty
            </div>
          </div>
          <div 
            class="formGroup">
            <label>ShellRot {{measurementType}} (inches)<span>*</span></label>
            <div style="display:flex">
              <input
                title=""
                type="number"
                class="formControl spinNone"
                min="0"
                required
                v-model="shellRotValue"
                @keypress="decimalPoint($event, shellRotValue)"
                @paste="decimalPoint($event, shellRotValue)"
                @keyup="shellRotChanged($event, shellRotValue)"/>
              <label v-if="!isTape" style="min-width:150px; margin-top:10px;margin-left:10px;" >
                Circ. = {{poleData.shellRotCircumference}}
              </label>
            </div>
            <div
              v-if="
                (formSubmitted && !poleData.shellRotCircumference) ||
                shellRotErr
              "
              class="error"
            >
              {{ shellRotErrMsg }}
            </div>


          </div>
          <button
            :class="[formValid ? 'btnblue' : 'btndef', 'txtUppercase', 'mb-2']"
            @click="onNext"
          >
            Next</button
          ><br />
          <div class="textCenter">
            <a
              href="javascript: void(0);"
              @click="backPrompt()"
              class="backLink txtUppercase"
            >
              CANCEL
            </a>
          </div>
          
          <select class="formDropDown form-control" v-model="toolSelect" @change="toolChange" style="background-color: #87cefa; margin-top:20px;">
            <option>Measurement Tool: Tape</option> 
            <option>Measurement Tool: Caliper</option>
          </select>
        </div>
      </div>
      <shared-footer></shared-footer>
      <div class="popUp" v-if="popup">
        <div class="popUpBox">
          <p class="popupText">{{ popupMsg }}</p>
          <div class="modalFooter">
            <button class="btnRounded" v-on:click="closePrompt()">
              {{ popupBtn2 }}
            </button>
            &nbsp;&nbsp;
            <button class="btnRoundedFull" v-on:click="confirmPrompt()">
              {{ popupBtn1 }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SimpleIDB from "../api/indexedDBService.js";
import SharedFooter from "../components/SharedFooter.vue";
import { store } from "../store/index.js";
import { mapGetters } from "vuex";
import { v4 as uuidv4 } from "uuid";

export default {
  name: "NewPole",
  data: () => ({
    poleData: {
      poleId: "",
      origCircumference: "",
      shellRotCircumference: "",
      createdOn: "",
      updatedOn: "",
      damages: [],
    },
    origValue: "",
    shellRotValue: "",
    formSubmitted: false,
    popup: false,
    popupMsg: "",
    popupBtn1: "OK",
    popupBtn2: "CANCEL",
    overwritePopup: false,
    shellRotErrMsg: "ShellRot Circumference cannot be empty",
    shellRotErr: false,
    formValid: false,
    editMode: false,
    cirValChanged: false,
    oldPoleId: "",
    poleIdChanged: false,
    clearDamages: false,
    toolSelect: "Measurement Tool: Tape",
    isTape: true,
    measurementType: "Circumference",
  }),

  components: {
    "shared-footer": SharedFooter,
  },

  mounted() {
    if (this.$route.params.editPole !== undefined) 
    {
      this.poleData = JSON.parse(this.getPoleRecord);
      this.editMode = true;
      this.oldPoleId = this.poleData.poleId;
    }

    if (localStorage.getItem('MeasurementTool'))
      if(localStorage.getItem("MeasurementTool")=="TAPE"){
        this.toolSelect = "Measurement Tool: Tape",
        this.isTape = true;

        this.measurementType = "Circumference";
        this.origValue = parseFloat(this.poleData.origCircumference).toFixed(2);
        this.shellRotValue = parseFloat(this.poleData.shellRotCircumference).toFixed(2);
      }
      else{
        this.toolSelect = "Measurement Tool: Caliper",
        this.isTape = false;
        
        this.measurementType = "Diameter";
        this.origValue = (parseFloat(this.poleData.origCircumference) / Math.PI).toFixed(2);
        this.shellRotValue = (parseFloat(this.poleData.shellRotCircumference) / Math.PI).toFixed(2);
      }

      if(!isNaN(parseFloat(this.poleData.origCircumference)))
        if(this.isTape)
        {
          this.origValue = parseFloat(this.poleData.origCircumference).toFixed(2);
          this.shellRotValue = parseFloat(this.poleData.shellRotCircumference).toFixed(2);
        }
        else
        {
            this.origValue = (parseFloat(this.poleData.origCircumference) / Math.PI).toFixed(2);
            this.shellRotValue = (parseFloat(this.poleData.shellRotCircumference) / Math.PI).toFixed(2);
        }
  },

  computed: {
    ...mapGetters(["getPoleRecord"]),
  },

  watch: {
    "poleData.poleId": function (newVal) {
      this.validateForm();
      if (this.editMode && newVal && this.oldPoleId != newVal) {
        this.poleIdChanged = true;
      } else {
        this.poleIdChanged = false;
      }
    },

    "poleData.origCircumference": function (oldVal, newVal) {
      this.validateForm();
      if (this.editMode && newVal && oldVal != newVal) {
        this.cirValChanged = true;
      } else {
        this.cirValChanged = false;
      }
    },

    "poleData.shellRotCircumference": function () {
      this.validateForm();
      // if (this.editMode && newVal && oldVal != newVal) {
      //   this.cirValChanged = true;
      // } else {
      //   this.cirValChanged = false;
      // }
    },
  },

  methods: {
    openDashboard() {
      this.$router.replace("/dashboard");
    },

    origChanged($event, val) {
      let valFloat = parseFloat(val.toString());
      
      if(!this.isTape)
        valFloat = (valFloat * Math.PI);
      this.poleData.origCircumference = valFloat.toFixed(2);
    },

    shellRotChanged($event, val){
      let valFloat = parseFloat(val.toString());
      
      if(!this.isTape)
        valFloat = (valFloat * Math.PI);
      this.poleData.shellRotCircumference = valFloat.toFixed(2);
    },

    decimalPoint($event, val) {
      try {
        let newVal = $event.clipboardData.getData("text");
        if (newVal) {
          if ((parseFloat(newVal) * 100) % 1 > 0) {
            $event.preventDefault();
            return false;
          }
        }
      } catch {
        if (
          val != null &&
          val.toString().indexOf(".") > -1 &&
          val.toString().split(".")[1].length > 1
        ) {
          $event.preventDefault();
          return false;
        }
      }
      return true;
    },

    validateShellRotCir() {
      let toolString = "Circumference"; 
      if(!this.isTape)
        toolString = "Diameter"
      if (!this.poleData.shellRotCircumference && this.formSubmitted) {
        this.shellRotErr = true;
        this.shellRotErrMsg = "ShellRot "+ toolString + " cannot be empty";
      } else if (
        parseFloat(this.poleData.shellRotCircumference) >
        parseFloat(this.poleData.origCircumference)
      ) {
        this.shellRotErr = true;
        this.shellRotErrMsg =
          "ShellRot " + toolString + " cannot be more than Original "+toolString;
      } else {
        this.shellRotErr = false;
      }
    },

    validateForm() {
      this.formSubmitted = true;
      this.validateShellRotCir();
      this.isFormValid();
    },

    isFormValid() {
      if (
        this.poleData.poleId !== "" &&
        this.poleData.origCircumference !== "" &&
        !this.shellRotErr
      )
        this.formValid = true;
      else 
        this.formValid = false;
    },

    backPrompt() {
      this.cirValChanged = false;
      this.poleIdChanged = false;
      this.clearDamages = false;
      if (
        this.poleData.poleId !== "" ||
        this.poleData.origCircumference !== "" ||
        this.poleData.shellRotCircumference !== ""
      ) {
        this.popupMsg = "Changes will not be saved. Continue?";
        this.popupBtn1 = "OK";
        this.popupBtn2 = "CANCEL";
        this.popup = true;
      } else {
        this.$router.replace("/dashboard"); //rs: even if the flow did not start form the dashborad page, it will go to the dashborad page
      }
    },

    closePrompt() {
      this.popup = false;
      if (this.poleIdChanged) {
        this.poleData.editAction = "Overwrite";
        this.circChanged();
        this.poleIdChanged = false;
      }
    },

    confirmPrompt() {
      this.popup = false;
      if (this.overwritePopup) {
        this.overwritePopup = false;
        this.addDamage();
      } else if (this.editMode) {
        if (this.poleIdChanged) {
          this.circChanged();
          this.poleIdChanged = false;
        } else if (this.cirValChanged && !this.clearDamages) {
          this.circChanged();
        } else if (this.clearDamages) {
          this.clearDamages = false;
          this.deleteDamages();
        } else {
          this.backToEvaluation();
        }
      } else {
        this.$router.replace("/dashboard");
      }
    },

    deleteDamages() {
      if (this.poleData.damages.length > 0) {
        this.updatePole();
      } else this.$router.replace("/dashboard");
    },

    removeObject(key, store, db_name) {
      try {
        return SimpleIDB.SimpleIDB.remove(key, store, db_name);
      } catch (e) {
        console.log(e);
      }
    },

    updatePole() {
      if (this.cirValChanged) 
        this.poleData.damages = [];
      if (this.poleIdChanged && !this.cirValChanged) {
        this.cloneDamages();
      } else {
        this.poleData.updatedOn = new Date();
        store.dispatch("setPoleRecord", JSON.stringify(this.poleData));
        this.$router.replace("/evaluation");
      }
    },

    cloneDamages() {
      if (this.poleData.damages) {
        let self = this;
        let len = this.poleData.damages.length;
        this.poleData.damages.forEach((damage, index) => {
          this.getObject(damage.ID, "damage-data", "SASC_DB").then(
            (res) => {
              self.createDamage(res, index, len);
            }
          );
        });
      }
    },

    createDamage(damage, index, len) {
      let self = this;
      damage.ID = uuidv4();
      this.insertObject(
        "damage-data",
        "SASC_DB",
        damage.ID,
        JSON.stringify(damage)
      ).then(() => {
        self.updateDamages(damage, index, len);
      });
    },

    updateDamages(d, index, len) {
      let damage = {
        ID: d.ID,
        type: d.TYPE,
      };
      this.poleData.damages[index] = damage;
      if (index == len - 1) {
        this.poleData.updatedOn = new Date();
        store.dispatch("setPoleRecord", JSON.stringify(this.poleData));
        this.$router.replace("/evaluation");
      }
    },

    insertObject(store, db_name, key, val) {
      try {
        let jsonVal = val.includes("{") ? JSON.parse(val) : val;
        return SimpleIDB.SimpleIDB.set(key, jsonVal, store, db_name);
      } catch (e) {
        console.log(e);
      }
    },

    onNext() {
      if (this.formValid) {
        if (this.editMode) {
          if (this.oldPoleId != this.poleData.poleId) {
            this.poleData.oldPoleId = this.oldPoleId;
          }
          if (this.poleIdChanged) {
            this.poleIdUpdated();
          } else {
            this.circChanged();
          }
        } else {
          let self = this;
          this.getObject(this.poleData.poleId, "pole-data", "SASC_DB").then(
            (res) => {
              if (res) {
                self.showOverwritePopup();
              } else {
                self.addDamage();
              }
            }
          );
        }
      }
    },

    poleIdUpdated() {
      this.popupMsg = "Please confirm action.";
      this.popupBtn1 = "Save As";
      this.popupBtn2 = "Overwrite";
      this.popup = true;
    },

    circChanged() {
      if (this.cirValChanged && this.poleData.damages.length > 0) {
        this.popupMsg = "Damages will need to be re-entered, Continue?";
        this.popupBtn1 = "OK";
        this.popupBtn2 = "CANCEL";
        this.clearDamages = true;
        this.popup = true;
      } else this.updatePole();
    },

    showOverwritePopup() {
      this.popup = true;
      this.overwritePopup = true;
      this.popupMsg = "Record already exists. Overwrite?";
    },

    addDamage() {
      let currentDate = new Date();
      this.poleData.createdOn = currentDate;
      this.poleData.updatedOn = currentDate;
      store.dispatch("setPoleRecord", JSON.stringify(this.poleData));
      this.$router.push("/evaluation");
    },

    getObject(key, store, db_name) {
      try {
        return SimpleIDB.SimpleIDB.get(key, store, db_name);
      } catch (e) {
        console.log(e);
      }
    },

    backToEvaluation() {
      this.$router.replace("/evaluation");
    },

    toolChange(event) {
      this.isTape = (event.target.value.toString() == "Measurement Tool: Tape");
      if(this.isTape)
      {
        this.measurementType = "Circumference";
        this.origValue = parseFloat(this.poleData.origCircumference).toFixed(2);
        this.shellRotValue = parseFloat(this.poleData.shellRotCircumference).toFixed(2);
        localStorage.setItem("MeasurementTool","TAPE");
      }
      else
      {
        this.measurementType = "Diameter";
        this.origValue = (parseFloat(this.poleData.origCircumference) / Math.PI).toFixed(2);
        this.shellRotValue = (parseFloat(this.poleData.shellRotCircumference) / Math.PI).toFixed(2);
        localStorage.setItem("MeasurementTool","CALIPER");
      }
      this.validateShellRotCir();
    },
  },
};
</script>
<style scoped lang="scss">
.popUp {
  text-align: center;
  position: fixed;
  top: 0px;
  background: rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100%;
  z-index: 1;
}
.modalFooter {
  border-top: 1px solid #d5d5d5;
  padding: 10px 10px;
}
.spinNone::-webkit-inner-spin-button,
.spinNone::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
.popupText {
  padding: 20px 20px;
}
.popUpBox {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0px 0px 5px #d2e4ff;
  padding: 0px;
  width: 300px;
  margin: auto;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.btnRounded {
  background: #fff;
  border: 2px solid #fff;
  padding: 6px;
  border-radius: 50px;
  color: #0072ff;
  font-size: 16px;
}
.btnRoundedFull {
  font-size: 16px;
  background: #fff;
  border: 2px solid #fff;
  padding: 6px;
  border-radius: 50px;
  color: #0072ff;
  min-width: 90px;
  cursor: pointer;
}
.mainPage {
  height: calc(100vh - 50px);
}
.headerLogo {
  img {
    width: 230px;
  }
}
.mainPageContent {
  margin: auto;
}
.listInline {
  list-style: none;
  padding-left: 0;
  font-size: 14px;
  margin: 1rem 0px;
  .listItem {
    display: inline-block;
    margin-right: 1rem;
    a {
      text-decoration: none;
    }
  }
  .listItem:last-child {
    margin-right: 0rem;
  }
}

.title1 {
  margin: 3% 0px;
  text-align: center;
  color: #003468;
  font-weight: 600;
}
.btndef {
  background: #ccc;
  border-radius: 5px;
  padding: 16px;
  color: #fff;
  font-size: 14px;
  border: 0px;
  cursor: pointer;
  font-weight: 600;
  width: 100%;
  margin: 18px 0px;
  pointer-events: none;
}
.btnblue {
  background: #003468;
  border-radius: 5px;
  padding: 16px;
  color: #fff;
  font-size: 14px;
  border: 0px;
  cursor: pointer;
  font-weight: 600;
  width: 100%;
  margin: 18px 0px;
  pointer-events: all !important;
}
.error {
  color: #f00;
  font-size: 12px;
}
.bottomFixed {
  position: fixed;
  bottom: 0px;
  width: 100%;
  text-align: center;
  background: #fff;
  z-index: 1;
  .listInline a {
    color: #003468;
  }
}
.backLink {
  color: #003468;
  font-size: 16px;
  text-decoration: none;
  font-weight: 600;
}
.contentPanel {
  width: 350px;
  text-align: center;
  margin: auto;
  padding-top: 2rem;
  padding-bottom: 50px;
}
.info1 {
  color: #003468;
  font-size: 14px;
  font-weight: 600;
  margin: 16px 0px;
}
//Tabs Only
/*@media (min-width: 768px) {
  .contentPanel {
    padding-top: 5rem;
  }
  .info1 {
    margin: 4rem 0px;
  }
  .listInline {
    margin: 2rem 0px;
  }
  //   .headerLogo{
  //   img{
  //     width: 230px;
  //     height: 48px;
  //   }
  // }
}*/
//Desktop only
@media (min-width: 1200px) {
  .listInline {
    margin: 1rem 0px;
  }
  .contentPanel {
    padding-top: 1rem;
  }
  .info1 {
    margin: 1rem 0px;
  }
  .title1 {
    margin: 3% 0px;
  }
}

//Small mobile
@media (max-width: 374.5px) {
  .contentPanel {
    width: 90% !important;
  }
}
</style>
